<template>
  <div class="welcome-main">
    <div class="w-100">
      <div class="text-center">
        <h1 class="display-3">Welcome</h1>

        <!-- {{ selectedValue }}
        ----------------------------------------
        -->
        <!-- :value="selected" -->

        <SelectWithInfinite
          :value="selected"
          :options="options"
          valueField="id"
          placeholder="Select product."
          textField="name"
          @input="(value) => (selected = value)"
        />
        <!-- :valueDisplay="value" -->
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/inputs/SelectWithInfinite";
import "vue-select/dist/vue-select.css";
// import countries from '../data/countries'

export default {
  name: "welcome",
  components: { MultiSelect },
  data: () => ({
    observer: null,
    limit: 100,
    search: "",
    options: [],

    selected: ["3", "10607", "203"],
  }),

  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    // this.observer = new IntersectionObserver(this.infiniteScroll);
    this.getProductList();
  },
  methods: {
    async getProductList(page) {
      this.isLoading = true;
      let data = await this.axios.post(`/MarketingAutomation/GetProductList`, {
        page: 1,
        search: page,
        take: 99999,
      });
      this.isLoading = false;

      this.options = data.data.detail.product_list;
    },
  },
};
</script>

<style scoped>
.welcome-main {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
